"use strict"

// ПОДКЛЮЧЕНИЕ

// require('babel-polyfill')
// require('./formdata-polyfill');

const $ = require("./jquery")
const jQuery = $
window.jQuery = $

// читать далее
const readmore = require("./jquery.readmore-symbols")

// автоформы
const autoforms = require("./autoforms")

// maskedInput
const maskedInput = require("./maskedinput")

// отправка форм по аякс
const forms = require("./forms")

// мобильное меню
const mobileMenu = require("./mobileMenu")

// плавный скрол к якорям
let autoScrollToTag = require("./autoScrollToTag")

// цены
const accounting = require("./accounting")

// галерея
const initPhotoSwipe = require("./photoswipe/initPhotoSwipe")

// Форматирование чисел в цене
$(".js-price").each(function (index) {
	let str = $(this).text()
	str = accounting.formatNumber(str)
	$(this).text(str)
})

// Обрабатываем телефон
let telListNode = document.querySelectorAll(".js-tel")
var telList = Array.prototype.slice.call(telListNode, 0)
telList.forEach(function (telInp) {
	maskedInput(telInp).mask("+7 (999) 999-99-99")
})

// Автоформы
let autoformListNode = document.querySelectorAll(".js-form")
var autoformList = Array.prototype.slice.call(autoformListNode, 0)

autoformList.forEach(function (form) {
	// console.log(form);
	autoforms.init(form, {
		Validators: {
			maskphone: {
				keys: "1234567890",
				errorMessage: "Type only numbers",
				validatorFunction: function validatorFunction(field) {
					return (field.valid = field.nodeLink.value.indexOf("_") < 0)
				},
				keypressValidatorFunction: false,
			},
		},
	})
})

// отправка форм по аякс
forms(".js-form")

// плавный скрол к якорям
autoScrollToTag({
	itemTag: ".js-scroll",
	duration: "50",
	speed: "1000",
	ofset: 30,
	parentTag: ".js-scroll-parent",
	noParentOfsetLessWhen: 1150,
})

// мобильное меню
mobileMenu({
	btnOpenTag: ".js-mobile",
	bodyClass: "is-menu-open",
})

initPhotoSwipe(".js-pswp-gallery")
