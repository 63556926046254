// v1.20

let $ = require("./jquery");
let metrika = require("./metrika")(53945932);

module.exports = function(selector) {

    // перебираем формы с нужным классом
    $.each($(selector), function(){
        // для каждой находим корень формы
        let newFormObj = $(this);

        // и в нем кнопку отправить, на которое вешаем событие клик
        newFormObj.find("[type='submit']").on("click", function(e){
            e.preventDefault();

            // console.log(newFormObj[0]);
            let formData = new FormData(newFormObj[0]);


            if (!newFormObj.hasClass("is-form-sending")) {
                $.ajax({
                    url: newFormObj.attr("data-action"),
                    type:'POST',
                    processData: false,
                    contentType: false,
                    data: formData,
                    beforeSend: function () {
                        newFormObj.addClass("is-form-sending");
                        $("body").addClass("is-form-sending");
                        // $("body").addClass("is-popup-open");
                        newFormObj.find(".js-preloader").addClass("is-active");
                        newFormObj.addClass("is-preloader-active");
                    },
                    success:function(data){
                        console.log("  Ответ сервера: " + data);
                        let success = newFormObj.find(".js-success");
                        let fail = newFormObj.find(".js-fail");

                        let formReset = function () {
                            newFormObj[0].reset();
                            success.removeClass("is-active");
                            fail.removeClass("is-active");
                            newFormObj.removeClass("is-form-sending");
                            $("body").removeClass("is-form-sending");
                            newFormObj.removeClass("is-answer-open");
                            $("body").removeClass("is-answer-open");
                            // чтобы активировать автоформу заново,
                            // поставим фокус первому полю
                            newFormObj.find("input")[0].focus();
                            var focusEvent = new CustomEvent("click", {
                                bubbles: true
                            });
                            newFormObj.find("input")[0].dispatchEvent(focusEvent);
                            // console.log("dispatchEvent");
                            // console.log("form reset");
                        }

                        setTimeout( function(){
                            console.log("sucGlob");
                            newFormObj.find(".js-preloader").removeClass("is-active");
                            newFormObj.removeClass("is-preloader-active");

                            if (data == 200) {
                                // console.log("success!");
                                success.addClass("is-active");
                                newFormObj.addClass("is-answer-open");
                                $("body").addClass("is-answer-open");
                                // console.log("suc1");
                                setTimeout( function(){
                                    // console.log("suc2");
                                    formReset();

                                },6000);
                                // отправка метрик
                                // ga('send', 'event', 'forma', 'otpravil');

                                // metrika.reachGoal('klik').then(function (res) {
                                //     if (res.status == "OK") {
                                //         console.log("  Ответ метрики: цель '" + res.goal + "'" + ", статус %cOK", "color: green");
                                //     }
                                // });



                            } else {
                                console.log("fail!");
                                fail.addClass("is-active");
                                newFormObj.addClass("is-answer-open");
                                $("body").addClass("is-answer-open");
                                // console.log("f1");
                                setTimeout( function(){
                                    // console.log("f2");
                                    // formReset();

                                },6000);
                            }

                        }, 3000);

                    },
                    error: function() {
                        let success = newFormObj.find(".success"),
                            fail =    newFormObj.find(".fail");

                        setTimeout( function(){
                            newFormObj.removeClass("is-form-sending");
                            $("body").removeClass("is-form-sending");
                            newFormObj.removeClass("is-answer-open");
                            $("body").removeClass("is-answer-open");
                            // success.removeClass("is-active");
                            // fail.removeClass("is-active");

                        },3000);

                    },
                    complete: function () {
                    }
                });
            }

            return false;

        });
    });
};